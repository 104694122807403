<template>
  <div class="Base">
    <ContainerCard :locationMsg="locationMsg">
      <!-- 搜索 -->
      <div class="search">
        <div class="title1">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="title-size">实训基地库</span>
        </div>
        <div class="search-box">
          <span
            ><img
              src="../../assets/images/政策公告-搜索.png"
              class="search-icon"
          /></span>
          <input
            class="input"
            v-model="input"
            placeholder="请输入您要搜索的内容"
          />
          <div class="search-button" @click="searchHandle">
            <span class="button-word">搜索</span>
          </div>
        </div>
      </div>
      <div class="Technology">
        <div class="technologyItem">
          <div class="title">级别</div>
          <div class="btnList">
            <span
              v-for="(item, index) in searchMsg.type"
              :key="item.value"
              :class="[
                'el-btn',
                activeTag === item.value ? 'active' : 'no-active',
              ]"
              @click="techHandle(item.value)"
              >{{ item.label }}
            </span>
          </div>
        </div>
      </div>
      <!-- 实训库列表 -->
      <div class="achievement-container-wrapper" v-empty="List">
        <div
          @click="toDetail(base.billid)"
          v-for="(base, index) in List"
          :class="[
            'achievement-container',
            index % 2 == 0
              ? 'achievement-container-left'
              : 'achievement-container-right',
          ]"
          :key="index"
        >
          <div class="achidevments">
            <el-image class="img" :src="httpConfig.baseURL+base.photo || defaultImg" alt="" v-if="base.photo" />
            <div class="no-imgbox" v-if="!base.photo">
              <el-image class="no-img" :src="base.photo || defaultImg" alt="" />
            </div>
            <div class="word-container">
              <span class="word"> {{ base.name }}</span>
              <div class="type">
                <span class="label">{{ base.level }}</span>
              </div>
              <div class="miniword-container">
                <span class="contact">建设单位</span>
                <span v-if="!base.mainbody" class="no-value">暂无</span>
                <span class="mini-word">{{ base.mainbody }}</span>
                <!-- 原先基地描述 -- 改展示建设主体 = 等接口 -->
                <!-- <span v-if="!base.memo" class="no-value">暂无</span>
                <span class="mini-word" v-html="base.memo"></span> -->

              </div>
            </div>
            <!-- <div class="bottomBox">
              <div class="inline"></div>
              <div class="location">
                <img
                  v-if="base.address"
                  class="img2"
                  src="../../assets/images/导航地址.png"
                  alt=""
                />
                <span class="word">{{ base.address }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <Pagination
        class="center"
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Search from "../../components/SixLibraries/Search.vue";
import Lab from "../../components/SixLibraries/Lab.vue";
import Pagination from "../../components/Pagination.vue";
import { searchBases } from "../../api/SixLibraries/Base/Base";
import ContainerCard from "../../components/newContainerCard.vue";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
import { mapGetters } from "vuex";
import { getPlainText } from "../../common/utils/tool";
import httpConfig from "../../common/utils/config";
export default {
  data() {
    return {
      httpConfig,
      defaultImg,
      activeTag: "all",
      input: "",
      searchMsg: {
        title: "级别",
        type: [
          {
            label: "全部",
            value: "all",
          },
          { label: "国家级", value: "1" },
          { label: "省级", value: "2" },
          { label: "市级", value: "3" },
          { label: "县级", value: "4" },
          { label: "校级", value: "5" },
          { label: "其他", value: "6" },
        ],
      },
      locationMsg: [],
      List: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        level: "", //技术领域
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      let res = await searchBases(that.params);
      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.memo = getPlainText(getPlainText(item.memo));
        that.searchMsg.type.find((it) => {
          if (it.value.toString() === item.level.toString()) {
            tmp.level = it.label;
          }
        });
        tmp.address = item.address ? item.address.replace(",", "") : "";
        return tmp;
      });
      that.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      that.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },

    // 分页
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    // 搜索
    searchHandle(val) {
      // console.log(this.input);
      this.params.keyword = this.input;
      // this.params.level = val === "all" ? "" : val;
      // this.activeTag = val;
      this.getData();
    },
    // 栏目切换
    techHandle(val){
      this.params.level = val === "all" ? "" : val;
      this.activeTag = val;
      this.getData();
    },
    // toDetails
    toDetail(id) {
      this.$router.push({ path: "/base/baseDetails", query: { id: id } });
    },
  },
  mounted() {},
  components: {
    Location,
    Search,
    Lab,
    Pagination,
    ContainerCard,
  },
};
</script>
<style scoped lang="scss">
.center {
  // justify-content: center;
  // margin: 3rem;
  // width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}

// 列表容器样式
@mixin setHeightAndWidth($w, $h) {
  width: $w;
  height: $h;
}
@mixin setFont($size, $lih, $col) {
  font-size: $size;
  font-family: 思源黑体 CN Medium;
  line-height: $lih;
  color: $col;
}
.search {
  height: 86px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title1 {
    img {
      @include setHeightAndWidth(8px, 10px);
      margin-right: 8px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
    .word {
      border: 0px;
    }
  }
  .search-box {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
}
.achievement-container-wrapper {
  width: 1260px;
  // height: 100%;
  // min-height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr; /// 三列三等分
  grid-gap: 0px 0px; // 行间距，列间距
  box-sizing: border-box;

  .achievement-container-left {
    border-left: 0px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    &:hover {
      padding-left: 30px;
    }
  }
  .achievement-container-right {
    padding-left: 30px;
  }
  .achievement-container {
    background: #ffffff;
    box-sizing: border-box;
    width: 630px;
    height: 150px;
    // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
    padding: 20px 16px 16px 0px;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    &:nth-child(odd) {
      border-right: 0px solid #f1f1f1;
      padding-left: 30px;
    }
    &:nth-child(even) {
      border-left: 0px solid #f1f1f1;
    }
    &:hover {
      box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
      z-index: 999;
    }
    .achidevments {
      display: flex;
      .img {
        width: 150px;
        height: 90px;
        border-radius: 6px;
      }
      .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
      .word-container {
        padding: 6px 20px 9px 20px;
        box-sizing: border-box;
      }

      .word {
        @include setFont(16px, 24px, #101010);
        font-weight: 600;
      }
      .miniword-container {
        // padding-top: 13px;
        width: 332px;
        align-self: flex-start;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        @include setFont(12px, 18px, #404040);
        .contact {
        color: #8896a7;
        font-size: 12px;
        margin-right: 20px;
        // left: 0;
        // top: 29px;
      }
      .no-value {
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #404040;
        }
        // margin-top: 16px;
        .mini-word {
          margin-top: -19px;
          margin-left: 78px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }    
      }
      .type {
        display: flex;
        flex-wrap: wrap;
        // margin-bottom: 13px;
        margin-top: 9px;
        margin-bottom: 10px;
        .label {
          border-radius: 3px;
          line-height: 14px;
          margin-right: 3px;
          border: 1px solid #2885ff;
          padding: 3px 5px 0px 5px;
          font-size: 12px;
          font-family: 思源黑体 CN Regular;
          font-weight: 400;
          color: #ffffff;
          background-color: #2885ff;
        }
      }

      .bottomBox {
        margin-top: 20px;
        height: 48px;
        width: 100%;
        padding: 0 14px;
        box-sizing: border-box;
        .inline {
          width: 100%;
          height: 1px;
          background: #eaeaea;
        }
        .location {
          display: flex;
          width: 100%;
          align-items: center;

          .img2 {
            margin: 0px 8px;
            height: 16px;
            width: 16px;
          }

          .word {
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

.Technology {
  /* padding: 10px 15px; */
  width: 1260px;
  height: 72px;
  /* border: 1px solid #f2f2f2; */
  /* border-radius: 5px; */
}
.technologyItem {
  height: 72px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.title {
  // width: 88px;
  // height: 21px;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  border-right: 1px solid #e5e5e5;
  margin-right: 16px;
  padding-right: 16px;
}
h3 {
  margin: 24px 0;
  font-size: 24px;
  font-weight: bold;
  color: #101010;
}
.btnList {
  // display: flex;
  .el-btn {
    display: inline-block;
    cursor: pointer;
    margin: 8px 16px 8px 0px;
    padding: 3px 8px;
    font-size: 14px;
    font-family: Regular;
    font-weight: 400;
    color: #101010;
  }
  .active {
    /* height: 38px; */
    background: #f5f9ff;
    border-radius: 6px;
    color: #2885ff;
    border: 1px solid #2885ff;
  }
  .no-active {
    border: none;
    color: #404040;
    background-color: #ffffff;
  }
  .no-active:hover {
    color: #2885ff;
    border: none;
  }
}

/* .el-btn:hover {
  color: #2885FF;
  border: none;
} */

@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
</style>
