<template>
  <div class="Search">
    <div class="level">
      <p>
        <b>{{ this.searchMsg.title }}</b>
      </p>
      <el-button
        @click="changeActive(item.value)"
        :class="{ active: curAct == item.value }"
        v-for="(item, index) in type"
        :key="index"
        ><span>{{ item.label }}</span></el-button
      >
    </div>
    <!-- 搜索框 -->
    <div class="search-container-wrapper">
      <div class="search-container">
        <span>查询：</span>
        <input type="text" placeholder="请输入您要搜索的内容" v-model="input"/>
        <button class="search-button" @click="search()">
          <i class="el-icon-search"></i>查找
        </button>
      </div>
    </div>
    <!-- <div class="searchBox">
      <el-input placeholder="请输入名称" v-model="input" clearable> </el-input>
      <el-button type="primary" @click="search(input)">查找</el-button>
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["searchMsg"],
  data() {
    return {
      input: "",
      type: this.searchMsg.type,
      curAct: 'all',
    };
  },
  methods: {
    search() {
      this.$emit("searchKey", {keyword:this.input,level:this.curAct});
    },
    // 改变样式
    changeActive(index) {
      this.curAct = index;
      this.search()
    },
  },
};
</script>
<style scoped lang="scss">
.Search {
  width: 100%;
  /* border: 1px solid #f2f2f2; */
  border-radius: 5px;
  margin-bottom: 20px;
  /* padding: 20px 40px; */
}
.searchBox {
  text-align: right;
}
.searchBox > .el-input {
  width: 220px;
}
.level > p {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #101010;
  margin: 20px 0;
}
.active {
  background-color: #14569f;
  color: #ffffff;
}
// 搜索框
.search-container-wrapper {
  width: 100%;
  height: 80px;
  background-color: #f2f2f2;

  .search-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
      line-height: 24px;
    }

    input {
      width: 600px;
      height: 46px;
      background: #ffffff;
      border-radius: 6px;
      border: 2px solid #14569f;
      margin-right: 14px;
      font-size: 23px;
      padding-left: 10px;
    }

    input::placeholder {
      padding-left: 7px;
      font-size: 18px;
      color: #999;
    }

    button {
      width: 120px;
      height: 46px;
      background: #14569f;
      border-radius: 4px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;

      i {
        margin-right: 14px;
      }
    }
  }
}
</style>
<style>
.level > .el-button {
  border-radius: 4px;
  margin: 0 14px 24px 0;
}
.level > .el-button > span {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
}
</style>
