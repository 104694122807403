<template>
  <div class="Lab">
    <div
      class="labItem"
      v-for="(item, index) in lsrLisst"
      :key="index"
      @click="toPage('LsrPlatformDetails')"
    >
      <div class="logo">
        <img :src="'http://oa.sinkr.cn/'+ item.photo" alt="" />
      </div>
      <p class="name">{{ item.name }}</p>
      <el-button
        class="type"
        size=small
        v-for="(item, index) in item.mainbody"
        :key="index"
        onClick="event.cancelBubble = true"
      >
        {{ item }}
      </el-button>
      <p class="location">地址：{{ item.address }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["labMsg", "lsrLisst"],
  data() {
    return {};
  },
  methods: {
    toPage: function (link) {
      // console.log(link);
      this.$router.push(`${this.$route.path}/${link}`);
    },
  },
};
</script>
<style scoped>
.Lab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Lab::after {
  content: "";
  width: 30%;
  border: 1px solid none;
  margin: 10px;
}
.labItem {
  width: 30%;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
  padding: 20px;
  margin: 10px;
}
.logo > img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.name {
  font-size: 18px;
  font-weight: bold;
}
.location {
  font-size: 14px;
  opacity: 0.8;
  border-top: 1px solid #ddd;
  margin: 10px 0;
  padding-top: 5px;
}
@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 768px) {

}
</style>